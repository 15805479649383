<template>
  <ModalFull @close="closeAction">
    <template #default>
      <div class="meeting-rooms__single flex fd-c ggap-10">
        <BaseLoad v-if="isLoad" class="grid jc-c z3 bg white box rel mh-100" />

        <!-- Preview -->
        <div class="meeting-rooms__single-preview grid gtc-auto-1 ggap-20 p-20">
          <div class="meeting-rooms__single-image cover" @click="openMedia">
            <small class="marker grey" v-tippy="'Всего изображений: ' + item.images.length">
              {{ item.images.length }}
            </small>
            <img :src="mainImage" />
          </div>
          <div class="grid ai-fs pr-20 ggap-5">
            <!-- Title -->
            <a href="#" class="meeting-rooms__single-title title cut fs-18">
              {{ item.title }}
            </a>

            <!-- Links -->
            <div class="meeting-rooms__single-sub flex fw ai-c ggap-5">
              <span class="flex ai-c ggap-5">
                <BaseIcon class="ic-14 grey" icon="location" />
                {{ item.location }}
              </span>
              <span class="dot"></span>
              <span class="flex ai-c ggap-5">
                <BaseIcon class="ic-14 grey" icon="users" />
                {{ item.usersCount }} человек
              </span>
            </div>

            <!-- Tags -->
            <div class="meeting-rooms__single-tags flex fw ggap-5-10">
              <span v-for="tag of item.tags" :key="tag.id">{{ tag.name }}</span>
            </div>
          </div>
        </div>

        <!-- Form -->
        <form class="grid ggap-20 p-20">
          <div class="flex ai-c ggap-20">
            <FormSelect
              label="Тип брони"
              placeholder="-"
              :options="locationOptions"
              v-model="formData.event_location_id"
            />
            <FormSelect
              label="Выберите группу участников"
              placeholder="-"
              :options="locationOptions"
              v-model="formData.event_location_id"
            />
          </div>

          <div class="flex fd-c ai-fs ggap-10">
            <div class="flex ai-fe ggap-20">
              <FormDate
                type="datetime"
                label="Дата и время начала"
                placeholder="-"
                required
                v-model="formData.date_event"
              />
              <FormDate
                type="datetime"
                label="Дата и время окончания"
                placeholder="-"
                required
                v-model="formData.date_event"
              />
              <button class="btn rounded cube cancel" type="button">
                <BaseIcon class="ic-20 red" icon="close" />
              </button>
            </div>
            <button class="btn grey sm" type="button">
              <BaseIcon class="ic-20 primary" icon="plus" />
              Добавить
            </button>
          </div>
        </form>

        <!-- Booking dates -->
        <div class="booking-dates p-20 grid ggap-20">
          <!-- Range -->
          <div class="booking-dates__range flex ai-c jc-c ggap-20">
            <button class="btn grey rounded cube" type="button">
              <BaseIcon class="ic-20 primary" icon="arrow-left" />
            </button>
            <div class="grid ggap-5 ta-center">
              <small class="fs-12 t-grey-ed">Показанно</small>
              <b>20 ноябрь 2022 - 30 ноябрь 2022</b>
            </div>
            <button class="btn grey rounded cube" type="button">
              <BaseIcon class="ic-20 primary" icon="arrow-right" />
            </button>
          </div>

          <!-- Schema -->
          <div class="booking-dates__schema grid ggap-5">
            <div class="booking-dates__schema-item grid gtc-auto-1 ggap-5 ai-c">
              <div class="booking-dates__schema-item-weekday">&nbsp;</div>
              <div class="booking-dates__schema-item-clocks flex ai-c ggap-5">
                <small
                  v-for="time of timeRange"
                  :key="time.id"
                  class="booking-dates__schema-item-clocks-item time fs-10 t-grey-ed flex ai-fe jc-c"
                >
                  {{ time.clock }}
                </small>
              </div>
            </div>

            <div v-for="w of weekRange" :key="w.weekday" class="booking-dates__schema-item grid gtc-auto-1 ggap-5 ai-c">
              <div class="booking-dates__schema-item-weekday fs-12 t-grey-ed">{{ w.weekday }}</div>
              <div class="booking-dates__schema-item-clocks flex ai-c ggap-5">
                <button v-for="time of timeRange" :key="time.id" class="booking-dates__schema-item-clocks-item cell">
                  &nbsp;
                </button>
              </div>
            </div>
          </div>

          <!-- Legend -->
          <div class="booking-dates__legend flex ai-c jc-c ggap-30">
            <div class="grid gtc-auto-1 ggap-10 ai-c">
              <button class="booking-dates__legend-item blue">&nbsp;</button>
              Мое бронирование
            </div>
            <div class="grid gtc-auto-1 ggap-10 ai-c">
              <button class="booking-dates__legend-item red">&nbsp;</button>
              Чужое бронирование
            </div>
            <div class="grid gtc-auto-1 ggap-10 ai-c">
              <button class="booking-dates__legend-item">&nbsp;</button>
              Свободные ячейки
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- Foot -->
    <template #foot>
      <div class="flex ai-c jc-fe ggap-10">
        <button class="btn transparent-grey" @click.prevent="closeAction">Отмена</button>
        <button class="btn primary">Забронировать</button>
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import { ModalFull, BaseIcon, BaseLoad, FormDate, FormSelect } from '@/components'
import { $busEmit } from '@/plugins'

// Data
const store = useStore()
const route = useRoute()
const router = useRouter()
const isLoad = ref(false)
const isOpen = ref(false)
const formData = ref({})
const item = ref(null)
const message = ref('')
const currentId = Number(route.params.id)

// Computed
const rows = computed(() => store.getters['meetingRooms/rows'])
const mainImage = computed(() =>
  item.value?.images?.length ? item.value?.images[0].url : require('@/assets/img/no-photo.jpg')
)
const weekRange = computed(() => {
  const res = []
  for (let i = 1; i < 8; i++) {
    res.push({
      id: i,
      weekday:
        i === 1
          ? 'ПН'
          : i === 2
          ? 'ВТ'
          : i === 3
          ? 'СР'
          : i === 4
          ? 'ЧТ'
          : i === 5
          ? 'ПТ'
          : i === 6
          ? 'СБ'
          : i === 7
          ? 'ВС'
          : ''
    })
  }
  return res
})
const timeRange = computed(() => {
  const res = []
  for (let i = 0; i < 24; i++) {
    res.push({
      id: i,
      clock: i < 10 ? '0' + i + ':00' : i + ':00'
    })
  }
  return res
})

// Watch
watch(message, () => {
  isOpen.value = true
})

// Created
getItem()
// $busOn('updateItem', () => getItem(false))

// Methods
function closeAction() {
  router.push('/meeting-rooms')
}
function openMedia() {
  $busEmit('openMedia', { selected: item.value?.images[0].id, arr: item.value?.images })
}
async function getItem(isHasLoad = true) {
  try {
    if (isHasLoad) isLoad.value = true
    if (rows.value?.length) {
      item.value = rows.value.find((el) => el.id === currentId)
    }
    // const { data } = await events.get(currentId)
    // item.value = data.data
  } catch (error) {
    console.log(error)
  } finally {
    isLoad.value = false
  }
}
</script>

<style lang="scss">
.meeting-rooms__single {
  width: 100%;

  &__delete {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--rgba-red-08);
    backdrop-filter: blur(5px);
    border-radius: var(--br);
  }

  &-preview {
    border-bottom: var(--b1);
  }

  &-image {
    width: 100px;
    height: 70px;
    border-radius: var(--br);
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #000;

      img {
        opacity: 0.8;
      }
    }

    .marker {
      position: absolute;
      right: 4px;
      bottom: 4px;
      z-index: 1;
    }
  }

  &-title {
    color: var(--text);
    font-weight: bold;
  }

  &-sub {
    span,
    a {
      display: inline-flex;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}

.booking-dates {
  &__schema {
    &-item {
      &-weekday {
        width: 24px;
      }

      &-clocks {
        &-item {
          flex: 1;
          width: 30px;
          height: 30px;

          &.time {
            // transform: rotate(-90deg);
          }

          &.cell {
            border: 0;
            border-radius: 6px;
            background-color: var(--grey);

            &:hover {
              background-color: var(--primary);
            }
          }
        }
      }
    }
  }

  &__legend {
    &-item {
      width: 20px;
      height: 20px;
      border: 0;
      background-color: var(--grey);
      border-radius: 5px;

      &.blue {
        background-color: var(--primary);
      }
      &.red {
        background-color: var(--red);
      }
    }
  }
}
</style>
